<template>
  <window-content>
    <div class="wrapper-sm">
      <e-row mr>
        <e-col style="min-width: 45%;">
          <date-input label="Data (De)" v-model="filters.data1"/>
        </e-col>

        <e-col style="min-width: 45%;">
          <date-input label="Data (Até)" v-model="filters.data2"/>
        </e-col>

        <e-col style="min-width: 90%">
          <erp-s-field
              view="tl"
              label="Arrematante"
          >
            <arrematante-input v-model="filters.arrematante" disable-new />
          </erp-s-field>
        </e-col>

        <e-col style="min-width: 90%">
          <erp-s-field
              view="tl"
              label="Identificador"
          >
            <erp-input v-model="filters.id" />
          </erp-s-field>
        </e-col>

        <e-col style="min-width: 90%">
          <erp-s-field
              view="tl"
              label="Mostrar vendas canceladas / desistências"
          >
            <div class="flex">
              <label class="flex items-center m-b-xs m-r-xs">
                <u-radio :val="true" v-model="filters.mostrarVendasCanceladas" class="m-r-xs" /> Sim
              </label>
              <label class="flex items-center m-b-xs m-r-xs">
                <u-radio :val="false" v-model="filters.mostrarVendasCanceladas" class="m-r-xs" /> Não
              </label>
            </div>
          </erp-s-field>
        </e-col>
      </e-row>
    </div>
    <window-footer class="text-right">
      <div class="inline-block window-actions">
        <u-btn label="Cancelar" icon="chevron-left" icon-type="fa" icon-style="light" flat no-caps
               @click="$uloc.window.close($root.wid)"/>
        <u-btn @click="gerar(formato)" :loading="loading" label="Gerar" icon="print" icon-type="fa" icon-style="light" flat no-caps/>
      </div>
    </window-footer>
  </window-content>
</template>

<script>
import {
  WindowContent,
  WindowHeader,
  WindowBody,
  WindowFooter,
  ErpSField,
  ErpInput,
  HelperInputBtn,
  ErpBox,
  ErpLabel
  /*ErpSelect*/
} from 'uloc-vue-plugin-erp'
import ERow from "@/components/layout/components/Row"
import ECol from "@/components/layout/components/Col"
import DateInput from "@/reuse/input/Date"
import {date, URadio} from 'uloc-vue'
import mixinPrint from "@/components/relatorios/components/mixinPrint"
import {relatorio} from "@/domain/relatorios/services"
import {datePtToEn} from "@/utils/date"
import ArrematanteInput from "@/components/arrematante/helpers/input/ArrematanteInput"
import createPrint from "@/components/relatorios/components/customPrint/createPrint"

let filters = {
  data1: null,
  data2: null,
  arrematante: null,
  documento: null,
  id: null,
  mostrarVendasCanceladas: true
}

export default {
  name: 'RelatorioEstoqueWindow',
  mixins: [mixinPrint],
  data() {
    return {
      formato: 'html',
      filters: JSON.parse(JSON.stringify(filters))
    }
  },
  mounted() {
  },
  computed: {},
  methods: {
    createPrint: createPrint,
    actionPrint (action) {
      console.log('Print Action ', action)
      this.gerar(action)
    },
    gerar(formato = 'html') {
      let data1, data2
      let filters = JSON.parse(JSON.stringify(this.filters))
      if (filters.data1) {
        data1 = datePtToEn(filters.data1)
        data2 = datePtToEn(filters.data2)
        if (!date.isValid(data1) || !date.isValid(data2)) {
          alert('Digite a data inicial e data final corretamente. Formato: dd/mm/yyyy')
          return
        }
        filters.data1 = data1
        filters.data2 = data2
      }

      this.loading = true
      relatorio('comprasPorArrematante', filters, formato)
          .then(response => {
            this.loading = false
            formato === 'html' && this.createPrint(response.data, () => import('@/components/relatorios/components/customPrint/DefaultPrint'), 'gerencial', 'Compras por Arrematante', 'landscape')
          })
          .catch(error => {
            this.loading = false
            if (formato === 'html') {
              this.alertApiError(error)
            } else{
              error.data.text().then(text => {
                this.alertApiError({data: JSON.parse(text)})
              })
            }
          })
    }
  },
  components: {
    ArrematanteInput,
    DateInput,
    ECol,
    ERow,
    WindowFooter,
    // WindowBody,
    // WindowHeader,
    WindowContent,
    ErpSField,
    ErpInput,
    // HelperInputBtn,
    // ErpBox,
    // ErpLabel
    // UEditor,
    URadio
    // ErpSelect
  }
}
</script>
