import printView from "@/components/relatorios/components/printView"

export default {
  data () {
    return {
      loading: false,
      loaded: null
    }
  },
  methods: {
    printView: printView
  }
}
