<template>
  <helper-input-btn>
    <erp-input
        ref="input"
        @input="__set"
        slot="input"
        simple-border
        v-model="description"
        @keydown.enter="searchArrematante"
        readonly
    >
      <u-popover v-if="this.findedData" hover :hover-delay="500" class="popover-user-profile" anchor="bottom middle"
                 self="top middle" :offset="[10, 10]">
        <div class="wrapper bg-white font-12">
          <div class="row">
            <div class="col m-r">ID: <strong>{{findedData.id}}</strong></div>
          </div>
          <div class="row m-t">
            <div class="col m-r">Apelido: <strong>{{findedData.apelido}}</strong></div>
          </div>
          <div class="row m-t">
            <div class="col m-r">Nome: <strong>{{findedData.pessoa.name}}</strong></div>
          </div>
          <div class="m-t">
            <e-btn @click="open(findedData.id)" label="Acessar arrematante" />
          </div>
        </div>
      </u-popover>
    </erp-input>
    <a @click="searchArrematante"><i class="erp-icon search min"></i> </a>
    <u-icon v-if="!disableNew" name="plus-circle" type="fa" icon-style="light" class="m-l-xs cursor-pointer" />
  </helper-input-btn>
</template>

<script>
import InputMixin from '../../../../reuse/input/mixin'
import {HelperInputBtn} from 'uloc-vue-plugin-erp'
import {UPopover} from 'uloc-vue'
import searchArrematante from '../../../arrematante/windows/search/SearchArrematante'
import windowArrematante from '../../../arrematante/windows/arrematante'

export default {
  name: 'ArrematanteInput',
  mixins: [InputMixin],
  props: {
    disableNew: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      findedData: this.findedDataObj
    }
  },
  watch: {
    findedData (v) {
      this.$emit('updateSelected', v)
    }
  },
  mounted () {
    if (this.findedDataObj) {
      this.description = this.findedDataObj.apelido + ' - ' + this.findedDataObj.pessoa.name
    }
  },
  components: {HelperInputBtn, UPopover},
  methods: {
    __updateInternal () {
      this.updateInternal((v) => v.id + ' - ' + v.pessoa.name)
    },
    searchArrematante: searchArrematante,
    windowArrematante: windowArrematante,
    open (id) {
      this.windowArrematante(id)
    }
  }
}
</script>

<style scoped>

</style>
