export default function (data, type = 'gerencial', title = 'Relatório Gerencial') {
  let window = 'printView-' + data.type  + '-' + data.id
  !this.$uloc.printView.get(window) && this.$uloc.printView.new({
    wid: window,
    title: title,
    windowClass: 'erp-print',
    contentClass: '',
    props: {
      data: data
    }
  }, () => import('@/components/relatorios/components/Print'))
    .then((wid) => {
    }) // return wid
}
